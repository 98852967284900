<script setup lang="ts">
import { ContentButtonVariant } from '#imports'

defineProps<{
  title?: string
  body?: string
  usps?: string[]
  uspsTitle?: string
  primaryButton?: {
    label?: string
    url?: string
  }
  secondaryButton?: {
    label?: string
    url?: string
  }
}>()

</script>

<template>
  <ContentBlockViewPartialContainer background-color="white" inner-padding>
    <div class="grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-12">
      <ContentBlockViewPartialTitle :title="title" />

      <div class="col-span-2 flex flex-col gap-y-6">
        <BaseParagraph
          v-if="body"
          size="lg"
          lead="relaxed"
        >
          {{ body }}
        </BaseParagraph>

        <div class="mt-2 flex gap-2">
          <BaseContentButton
            v-if="primaryButton && primaryButton.url && primaryButton.label"
            :to="primaryButton.url"
            :variant="ContentButtonVariant.Secondary"
          >
            {{ primaryButton.label }}
          </BaseContentButton>

          <BaseContentButton
            v-if="secondaryButton && secondaryButton.url && secondaryButton.label"
            :to="secondaryButton.url"
            :variant="ContentButtonVariant.White"
          >
            {{ secondaryButton.label }}
          </BaseContentButton>
        </div>
      </div>
      <div class="col-span-1 flex flex-col items-start justify-start gap-y-2 pt-8 md:pl-8 md:pt-0">
        <h4>{{ uspsTitle }}</h4>
        
        <div
          v-for="(usp, index) in usps"
          :key="index"
          class="bg-secondary-500 w-full rounded-full px-4 py-2 font-bold text-white"
        >
          {{ usp }}
        </div>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>
