<script setup lang="ts">
import { ContentButtonVariant, DualCtaVariant } from '#imports'

const props = defineProps<{
  // TODO: we could also add a theme padding advanced prop
  variant: DualCtaVariant

  leftTitle: string
  leftBody: string
  leftButtonText: string
  leftButtonUrl: string
  rightTitle: string
  rightBody: string
  rightButtonText: string
  rightButtonUrl: string
  horizontalPadding?: boolean
}>()

const { md } = useTailwindBreakpoints()

</script>

<template>
  <ContentBlockViewPartialContainer background-color="transparent" :inner-padding="variant != DualCtaVariant.FullWidth && md">
    <div class="bg-secondary-500 grid grid-cols-1 gap-6 rounded-xl px-8 py-10 text-white md:grid-cols-2">
      
      <div class="flex flex-col items-start gap-y-4 rounded-xl py-4 md:px-8">
        <BaseHeading size="2xl" as="h3">
          {{ leftTitle }}
        </BaseHeading>
        <BaseParagraph>
          {{ leftBody }}
        </BaseParagraph>
        <BaseContentButton
          :to="leftButtonUrl"
          :variant="ContentButtonVariant.Light"
        >
          {{ leftButtonText }}
        </BaseContentButton>
      </div>

      
      <div class="flex flex-col items-start gap-y-4 border-t border-white/80 py-4 md:border-l md:border-t-0 md:px-8">
        <BaseHeading size="2xl" as="h3">
          {{ rightTitle }}
        </BaseHeading>
        <BaseParagraph>
          {{ rightBody }}
        </BaseParagraph>
        <BaseContentButton
          :to="rightButtonUrl"
          :variant="ContentButtonVariant.Light"
        >
          {{ rightButtonText }}
        </BaseContentButton>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>
