<script setup lang="ts">
const {
  title = undefined,
  maxItems = 3,
  showDate = true,
  showSummary = true,
  pageType = PageType.News,
} = defineProps<{
  title?: string
  maxItems?: number
  showDate?: boolean
  showSummary?: boolean
  pageType?: PageType
}>()

const { t } = useI18n()

const { data: newsItems, status, error } = await fetchNews(pageType)

// const newsItems = ref<Page[]>([])

const { cardRadiusClass } = useThemeClasses()

const newsItemsLimited = computed(() => newsItems?.value?.slice(0, maxItems || 100))

</script>

<template>
  <ContentBlockViewPartialContainer class="w-full">
    <ContentBlockViewPartialTitle
      v-if="title"
      :title="title"
      class="mb-4"
    />

    <div v-if="status === 'pending'" class="flex justify-center py-8">
      <BasePlaceload class="h-8 w-full" />
    </div>

    <div v-else-if="error" class="text-danger-500 py-4">
      {{ $t('errors.loadingFailed') }}
    </div>

    <div v-else-if="newsItems?.length === 0" class="py-4 text-gray-500">
      {{ t('noItems') }}
    </div>

    <div v-else class="w-full overflow-hidden">
      
      <div class="scrollbar-hide w-full overflow-x-auto py-2">
        
        <div class="flex space-x-4 pb-2">
          
          <div
            v-for="(item, index) in newsItemsLimited"
            :key="index"
            class="w-[300px] flex-none sm:w-[320px] md:w-[500px]"
          >
            
            <NuxtLink
              :to="`/nieuws/${item.slug}`"
              as="div"
              class="group/news-card dark:bg-muted-900 flex h-full gap-4 overflow-hidden bg-white p-6 hover:cursor-pointer"
              :class="cardRadiusClass"
            >
              
              <div class="text-theme-content flex flex-1 flex-col">
                <h3 class="mb-1 text-lg font-semibold leading-tight">
                  {{ item.title }}
                </h3>
                <p v-if="showDate" class="mb-2 text-sm opacity-80">
                  {{ item.createdAt ? $d(new Date(item.createdAt), 'date') : '' }}
                </p>
                <BaseParagraph
                  v-if="showSummary && item.summary"
                  class="flex-1 text-sm"
                >
                  {{ item.summary }}
                </BaseParagraph>

                <p
                  class="relative mt-4 flex items-center gap-2 text-sm font-bold"
                >
                  {{ t('readMore') }}
                  <Icon
                    name="mynaui:fat-arrow-up-right-solid"
                    class="bg-secondary-500 group-hover/news-card:bg-secondary-600 size-8 rounded-full p-2 text-white transition-all duration-300 ease-out group-hover/news-card:translate-x-1 group-hover/news-card:translate-y-[-4px]"
                  />
                </p>
              </div>

              
              <!-- <div
                v-if="item.headerImageUrl"
                class="relative w-1/3 overflow-hidden rounded-xl"
              >
                <img
                  :src="item.headerImageUrl"
                  :alt="item.title"
                  class="size-full object-cover transition-transform duration-300 ease-out group-hover/news-card:scale-110"
                >
              </div> -->
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<i18n lang="json">
{
  "en": {
    "noItems": "No news items found",
    "readMore": "Read more"
  },
  "nl": {
    "noItems": "Geen nieuwsberichten gevonden",
    "readMore": "Lees meer"
  }
}
</i18n>
